import { Routes, Route } from "react-router-dom";
import { useState } from "react";

// components
import Home from "./component/Home";
import NotFoundPage from "./component/NotFoundPage";
import Introduction from "./component/Introduction";
import Board from "./component/Board";
import Write from "./component/write";
import Detail from "./component/Detail";
import Login from "./component/Login";
import NoPermission from "./component/NoPermission";
import { useEffect } from "react";

// Context
import LocaleContext from "./context/localeContext.js";

// App
function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    fetch("/api/islogined")
      .then((res) => res.text())
      .then((data) => {
        setIsAuthorized(JSON.parse(data).ok);
      });
  }, []);

  return (
    <>
      <LocaleContext.Provider value={isAuthorized}>
        <Routes>
          {isAuthorized ? (
            <>
              <Route path="/write/:category" element={<Write></Write>}></Route>
              <Route
                path="/update/:category/:id"
                element={<Write isUpdate={true}></Write>}
              ></Route>
            </>
          ) : (
            <>
              <Route path="/login" element={<Login></Login>}></Route>
              <Route
                path="/write/:category"
                element={<NoPermission></NoPermission>}
              ></Route>
              <Route
                path="/update/:category/:id"
                element={<NoPermission></NoPermission>}
              ></Route>
            </>
          )}
          <Route path="/" element={<Home />}></Route>
          <Route path="/introduction" element={<Introduction />}></Route>
          <Route path="/board/:category" element={<Board />}></Route>
          <Route
            path="/detail/:category/:id"
            element={<Detail isAuthorized={isAuthorized}></Detail>}
          ></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Routes>
      </LocaleContext.Provider>
    </>
  );
}
export default App;
