function Footer() {
  return (
    <footer className='bg-dark py-5'>
      <div className="container">
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <p className='text-white'>
          주소: 광주광역시 북구 설죽로 230, 용주빌딩 2층 제1호 <br />
          대표 전화: (062) 529-2114 (월~금: 09:00~18:00) <br />
          Fax: (062) 529-2115 <br />

          Copyright 2023. KORIME. All rights reserved <br />
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer