import "../App.scss";
import {
  ChevronDown,
  EnvelopeAtFill,
  PlusCircle,
  TelephoneFill,
} from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

// components
import Footer from "./footer";
import Header from "./header";

// Home
function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="Home">
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </div>
  );
}

function Main() {
  let [gwangju, setGwangju] = useState(false);
  let [damyang, setDamyang] = useState(false);
  let [naju, setNaju] = useState(false);
  let [suncheon, setSuncheon] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <main>
      <section className="sec1">
        <div className="bg-main">
          <div className="blur blur_sec1 d-flex flex-column align-items-center justify-content-center">
            <div className="block"></div>
            <h1 className="text-center text-white mb-5 px-3">
              지역･나라 발전을 위한 창조적 선도그룹!
              <br /> 한국경영평가연구원
            </h1>
            <div className="moveUpDown">
              <ChevronDown size={32} color={"#fff"}></ChevronDown>
            </div>
          </div>
        </div>
      </section>
      <section className="sec2 bg-black py-5">
        <div className="container py-5">
          <div className="row" data-aos="fade-down" data-aos-duration="500">
            <div className="col-md-5 col-12">
              <div className="text d-flex flex-column justify-content-center">
                <h6 className="text-primary mb-3">연구원 소개</h6>
                <h1 className="text-white mb-4">
                  글로벌 경쟁시대, 지방시대!
                  <br /> 학제간 종합학술 연구기관
                </h1>
                <p className="text-white">
                  한국경영평가연구원은 글로벌 경쟁시대, 지방시대를 맞이하여{" "}
                  <br />
                  공·사 부문별 계획·진단·분석·평가·조정 등의 학제간 <br />
                  종합학술 연구를 목적으로 설립된 정부인가 연구기관입니다.
                </p>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="mt-lg-0 mt-5 img d-flex justify-content-end">
                <img
                  className="img"
                  src={process.env.PUBLIC_URL + "/img/report_group.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec3">
        <div className="bg-sec3">
          <div className="blur_sec3 blur-inverse d-flex flex-column justify-content-center align-items-center">
            <div className="bg-blur text-center py-5 px-lg-5 px-3">
              <h4 className="text-muted mb-5">Team Misson</h4>
              <h1 className="text-white lh-base fs-lg-1 fs-6">
                지난 30여 년 동안 <br />
                ‘지역경쟁력이 곧 국가경쟁력’이라는 가치 아래 <br />
                ‘지역의 새로운 성장 동인(driving force) 발굴’ <br />
                중심으로 약 500여 건의 창조적인 대안을 <br />
                제시하는데 주력했습니다. <br />
                <br />
                한국경영평가연구원은 <br />
                ‘창조적 혁신’에 기반을 둔 융합연구를 통해 <br />
                지역공동체는 물론, 한국 사회 발전을 위한 <br />
                실천적 정책대안(Solution)을 제시하는데 <br />
                최선을 다하고자 합니다.
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="sec4 text-center my-5 pt-5" id="sec4">
        <div className="container">
          <div className="text pb-5 mb-5">
            <h6 className="text-primary">프로젝트</h6>
            <h1 className="mb-4">한국경영평가연구원은</h1>
            <div ref={ref}>
              {inView ? (
                <h2>
                  <CountUp
                    start={0}
                    end={30}
                    duration={2.5}
                    className="text-primary"
                  />{" "}
                  여년 동안 <br />
                  <span>공공･민간부문 영역에 걸쳐</span> <br />
                  <CountUp
                    start={0}
                    end={500}
                    duration={2.5}
                    className="text-primary"
                  />{" "}
                  여개의 연구 및 컨설팅을 진행하였습니다. <br />
                </h2>
              ) : (
                <h2>
                  0여년 동안 <br />
                  <span>공공･민간부문 영역에 걸쳐</span> <br />
                  0개의 연구 및 컨설팅을 진행하였습니다. <br />
                </h2>
              )}
            </div>
          </div>
          <div className="portfolio-image text-start d-flex flex-wrap justify-content-center">
            <div
              className={`flex-item bg-damyang ${
                damyang ? "transition-damyang" : ""
              }`}
              onClick={() => {
                setDamyang(!damyang);
              }}
            >
              <div className="img-blur">
                <h6 className="text-primary intro">
                  수익모델 개발 및 투자유치
                </h6>
                <h2 className="title">
                  역발상! <br />
                  한강이남 최대규모 도시개발사업
                </h2>
                <p className="detail">
                  담양 담빛문화지구 수익모델 개발 및 투자유치 <br />
                  - 약 38만평(1,26㎢) 도시개발사업 성공에 기여 <br />
                  - 규모의 民資 투자유치 <br />
                  - 인구 약 4,000명 유입 <br />
                </p>
                <PlusCircle size={28} className="icon mt-3"></PlusCircle>
              </div>
            </div>
            <div
              className={`flex-item bg-gwangju ${
                gwangju ? "transition-gwangju" : ""
              }`}
              onClick={() => {
                setGwangju(!gwangju);
              }}
            >
              <div className="img-blur">
                <h6 className="text-primary intro">아시아문화교류권 조성</h6>
                <h2 className="title">
                  도심문화자원 활용! <br />
                  종교관광자원 상품화
                </h2>
                <p className="detail">
                  아시아 문화중심도시(문화교류권) 역사문화마을 만들기 학술연구{" "}
                  <br />- 사업비 400억 원 확정(국비포함)
                </p>
                <PlusCircle size={28} className="icon mt-3"></PlusCircle>
              </div>
            </div>
            <div
              className={`flex-item bg-naju ${naju ? "transition-naju" : ""}`}
              onClick={() => {
                setNaju(!naju);
              }}
            >
              <div className="img-blur">
                <h6 className="text-primary intro">나주 도시얼굴만들기 연구</h6>
                <h2 className="title">
                  전국공모 1위! <br />
                  협상에 의한 계약 체결
                </h2>
                <p className="detail">
                  나주 도시얼굴만들기 계획수립 연구 <br />
                  - 도심재생, 특성별 발전모델 구축 등 <br />
                  - 생명의 땅 나주! 통합브랜드 개발!! <br />- 연구비 179백만 원
                </p>
                <PlusCircle size={28} className="icon mt-3"></PlusCircle>
              </div>
            </div>
            <div
              className={`flex-item bg-suncheon ${
                suncheon ? "transition-suncheon" : ""
              }`}
              onClick={() => {
                setSuncheon(!suncheon);
              }}
            >
              <div className="img-blur">
                <h6 className="text-primary intro">순천 원도심 재생 연구</h6>
                <h2 className="title">
                  지역 특성요인 활용 <br />
                  원도심 활성화 전략 도출
                </h2>
                <p className="detail">
                  순천 기존도심 활성화 기본계획 연구 <br />- 연구비 105백만 원
                </p>
                <PlusCircle size={28} className="icon mt-3"></PlusCircle>
              </div>
            </div>
          </div>
          <div className="more-btn mt-3">
            <div
              className="btn btn-primary fs-5"
              onClick={() => {
                window.location.href = "/board/history";
              }}
            >
              <div className="py-2 px-3 d-flex align-items-center">
                <PlusCircle size={20} className="me-2" />더 많은 연구사업
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec5 py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12 order-md-1 order-2 text-center">
              <img
                className="img mt-lg-0 mt-3"
                src={process.env.PUBLIC_URL + "/img/contact.png"}
                alt=""
              />
            </div>
            <div className="col-lg-5 col-12 order-md-2 order-1 text-center text-lg-start">
              <h6 className="text-primary">연구 문의 & 제안</h6>
              <h1>
                지역 및 국가 발전을 위한 <br />
                명확한 솔루션
              </h1>
              <p className="mb-5">
                30년 간 연구 성과로 검증된 한국경영평가연구원입니다. <br />
                지금 문의하셔서 더 나은 대안(代案)을 만드십시오.
              </p>
              <div className="contact-info">
                <div className="d-flex align-items-center justify-content-lg-start justify-content-center phone mb-2">
                  <TelephoneFill size={28}></TelephoneFill>
                  <span className="ps-2">062-529-2114</span>
                </div>
                <div className="d-flex align-items-center justify-content-lg-start justify-content-center email">
                  <EnvelopeAtFill size={28}></EnvelopeAtFill>
                  <span className="ps-2">korime@daum.net</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
