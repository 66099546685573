import React from "react";
import { useState } from "react";

import Header from "./header";
import Footer from "./footer";

function Login() {
  return (
    <>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </>
  );
}

function Main() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function handleClickLogin() {
    await fetch("/api/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((res) => res.text())
      .then((data) => {
        if (JSON.parse(data).isSuccess) {
          alert(JSON.parse(data).message);
          window.location.href = "/";
        } else {
          alert(JSON.parse(data).message);
        }
      })
      .catch((err) => alert(err));
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleClickLogin();
    }
  }

  return (
    <>
      <div className="container" style={{ marginTop: "125px" }}>
        <div
          className="app d-flex flex-column align-items-center mx-auto"
          style={{ maxWidth: "400px" }}
        >
          <a href="/">
            <img src={process.env.PUBLIC_URL + "/korime_logo.png"} alt="" />
          </a>
          <h2 className="my-3">로그인하기</h2>
          <span className="text-center fw-light">
            아이디와 비밀번호가 기억이 나지 않는다면, <br /> 관리자에게
            문의하세요.
          </span>
          <div className="my-5 input-container w-100 d-flex flex-column align-items-center">
            <input
              className="form-control mb-3 w-75"
              type="text"
              placeholder="아이디"
              value={username}
              onKeyDown={handleKeyDown}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
            />
            <input
              className="form-control mb-3 w-75"
              type="password"
              placeholder="비밀번호"
              onKeyDown={handleKeyDown}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="password"
            />
            <button className="btn btn-primary w-75" onClick={handleClickLogin}>
              로그인
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
