import Header from "./header";
import Footer from "./footer";

const imgUrlScheme = process.env.PUBLIC_URL + "/img/introduction";

function Introduction() {
  return (
    <>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </>
  );
}

function Main() {
  return (
    <>
      <div className="container" style={{ marginTop: "125px" }}>
        <div className="my-5 d-flex flex-column align-items-center">
          <div className="img-container">
            <img className="w-100" src={imgUrlScheme + "/hello.jpg"} alt="" />
          </div>
          <div className="img-container mt-5">
            <img className="w-100" src={imgUrlScheme + "/profile.jpg"} alt="" />
            <img
              className="w-100"
              src={imgUrlScheme + "/primary_ref.jpg"}
              alt=""
            />
            <img
              className="w-100"
              src={imgUrlScheme + "/purpose.jpeg"}
              alt=""
            />
            <img className="w-100" src={imgUrlScheme + "/history.jpg"} alt="" />
            <img className="w-100" src={imgUrlScheme + "/bizMap.jpeg"} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Introduction;
