import Header from "./header";
import Footer from "./footer";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { useState, React, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FileImage, FiletypePdf } from "react-bootstrap-icons";
import axios from "axios";

function Write({ isUpdate }) {
  return (
    <>
      <Header></Header>
      <Main isUpdate={isUpdate}></Main>
      <Footer></Footer>
    </>
  );
}

function Main({ isUpdate }) {
  const modules = {
    toolbar: [
      [{ size: ["small", "medium", "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "bullet" }],
      ["link", "image"],
      [{ list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const { category, id } = useParams();

  const [isUpdateState, setIsUpdateState] = useState(isUpdate);
  const [contents, setContents] = useState("");
  const [title, setTitle] = useState("제목 없음");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    if (isUpdateState) {
      fetch(`/api/getone/${category}/${id}`)
        .then((response) => response.text())
        .then((data) => {
          const payload = JSON.parse(data);
          if (payload.file) {
            const fileExtension = payload.file.slice(-3);
            if (
              fileExtension === "jpg" ||
              fileExtension === "jpeg" ||
              fileExtension === "png"
            ) {
              setFileType("img");
            } else if (fileExtension === "pdf") {
              setFileType("pdf");
            } else {
              console.log("지원하지 않는 파일 형식입니다.");
            }
          }
          if (payload.file) {
            setFileName(payload.file);
          }
          setTitle(payload.title);
          setContents(payload.contents);
        })
        .catch((err) => {
          alert(err);
        });
    }
  }, [isUpdateState]);

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleContentChange(content) {
    setContents(content);
  }

  // Start:: 글쓰기 POST REQUEST
  function formatDateToSQLFormat(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 0-based month
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function handleContentSubmit({ title, contents }) {
    const formData = new FormData();
    formData.append("file", file);

    const contentObj = {
      author: "연구원",
      title: title,
      contents: contents,
      date: formatDateToSQLFormat(new Date()),
      file: formData,
      filename: file?.name,
    };

    for (const [key, value] of Object.entries(contentObj)) {
      formData.append(key, value);
    }

    axios
      .post(`/api/post/${category}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert(response.data.message);
        window.location.href = `/board/${category}`;
      })
      .catch((err) => {
        alert(err);
      });
  }
  // End:: 글쓰기 POST REQUEST

  function handleClickUpdateBtn({ title, contents }) {
    const contentObj = {
      title: title,
      contents: contents,
      date: formatDateToSQLFormat(new Date()),
    };
    fetch(`/api/update/${category}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contentObj),
    })
      .then((response) => response.text())
      .then((response) => {
        alert(JSON.parse(response).message);
        window.location.href = `/board/${category}`;
      })
      .catch((err) => {
        alert(err);
      });
  }

  return (
    <>
      <div className="container" style={{ margin: "125px auto" }}>
        <div className="app mx-auto" style={{ width: "fit-content" }}>
          <h3 className="text-center">
            게시글 {isUpdate ? "수정하기" : "작성하기"}
          </h3>
          <hr />
          <div className="file-upload mb-4">
            <div className="input-group">
              <div>
                <label htmlFor="formFileMultiple" className="form-label">
                  {isUpdateState ? (
                    <div>수정하기에서 파일을 수정할 수 없습니다.</div>
                  ) : (
                    <div>1개의 게시물당 1개의 파일만 업로드 가능합니다.</div>
                  )}
                </label>
                {isUpdateState ? (
                  <div className="btn btn-outline-primary d-block">
                    <div className="file-download d-flex align-items-center">
                      {fileType === "img" ? (
                        <FileImage size={30} />
                      ) : (
                        <FiletypePdf size={30} />
                      )}
                      <div className="text ms-2">{fileName}</div>
                    </div>
                  </div>
                ) : (
                  <input
                    className="form-control"
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    id="formFileMultiple"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="title mb-3">
            {isUpdateState ? (
              <input
                className="form-control"
                type="text"
                value={title}
                onChange={(c) => {
                  setIsUpdateState(false);
                  handleTitleChange(c);
                }}
              />
            ) : (
              <input
                className="form-control"
                type="text"
                placeholder="제목을 입력하세요."
                onChange={handleTitleChange}
              />
            )}
          </div>
          <div className="editor-container" style={{ minHeight: "450px" }}>
            {isUpdateState ? (
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder="write!"
                value={contents}
                onChange={(c) => {
                  setIsUpdateState(false);
                  handleContentChange(c);
                }}
                style={{ height: "400px" }}
              ></ReactQuill>
            ) : (
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder="write!"
                onChange={handleContentChange}
                style={{ height: "400px" }}
              ></ReactQuill>
            )}
          </div>
          <div className="action mt-2">
            <div className="d-flex justify-content-end">
              <div
                className="btn btn-outline-primary me-2 px-4"
                onClick={() => {
                  setContents("");
                  window.location.href = `/board/${category}`;
                }}
              >
                작성취소
              </div>
              {isUpdate ? (
                <div
                  className="btn btn-primary px-4"
                  onClick={() => {
                    handleClickUpdateBtn({
                      title: title,
                      contents: contents,
                    });
                  }}
                >
                  수정하기
                </div>
              ) : (
                <div
                  className="btn btn-primary px-4"
                  onClick={() => {
                    handleContentSubmit({
                      title: title,
                      contents: contents,
                    });
                  }}
                >
                  작성하기
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Write;
