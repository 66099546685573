import React from "react";

function NotFoundPage() {
  return (
    <div style={{ textAlign: "center", paddingTop: "50px" }}>
      <h1 style={{ fontSize: "3rem" }}>404 Error</h1>
      <p style={{ fontSize: "1.5rem" }}>Page not found</p>
    </div>
  );
}

export default NotFoundPage;
