import Header from "./header";
import Footer from "./footer";
import "../css/board.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// 게시판 기능
function Board() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const { category } = useParams();

  useEffect(() => {
    const mainHeader = document.querySelector("#main-header");
    if (mainHeader) {
      setHeaderHeight(mainHeader.clientHeight);
    }
  }, []);

  return (
    <>
      <Header></Header>
      <Main headerHeight={headerHeight} category={category}></Main>
      <Footer></Footer>
    </>
  );
}

function Main({ headerHeight, category }) {
  const [isSearched, setIsSearched] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [contents, setContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  // api
  useEffect(() => {
    fetch(`/api/get/${category}?page=${currentPage}`)
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        const response = JSON.parse(res);
        setContents(response.paginatedData);
        setTotalPage(response.totalPages);
      })
      .catch((err) => {
        alert("err: ", err);
      });
  }, [category, currentPage]);

  const pageNumbers = Array.from(
    { length: totalPage },
    (_, index) => index + 1
  );
  const path = {
    history: "연구 사업",
    issue: "이슈&솔루션",
    news: "연구원 소식",
    country: "지역&나라발전",
  };

  function handleClickWriteBtn(category) {
    window.location.href = `/write/${category}`;
  }

  function handleClickRow(num) {
    window.location.href = `/detail/${category}/${num}`;
  }

  // Start:: Search
  function handleChangeSearchValue(e) {
    setSearchValue(e.target.value);
  }
  function handleClickSearchBtn() {
    if (searchValue === "") {
      setIsSearched(false);
    } else {
      setIsSearched(true);
      const filteredData = contents.filter((item) =>
        item.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearchResult(filteredData);
    }
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      handleClickSearchBtn(e);
    }
  }
  // End:: Search

  return (
    <>
      <div
        className="container history py-5"
        style={{ marginTop: `${headerHeight}px` }}
      >
        <div className="page-header d-flex flex-column align-items-center">
          <h3 className="mb-5">{path[category]}</h3>
          <div className="w-100 d-flex justify-content-between">
            <div className="action write">
              <div
                className="btn btn-outline-primary"
                onClick={() => {
                  handleClickWriteBtn(category);
                }}
              >
                글쓰기
              </div>
            </div>
            <div className="search d-flex">
              <input
                className="form-control"
                placeholder="검색어를 입력하세요."
                type="text"
                name="search"
                id="search"
                onChange={handleChangeSearchValue}
                onKeyDown={handleKeyPress}
              />
              <input
                className="btn btn-primary ms-2 px-3"
                type="button"
                value="검색"
                onClick={handleClickSearchBtn}
              />
            </div>
          </div>
        </div>
        <div className="page-body my-3">
          <table className="table">
            <thead>
              <tr>
                <td className="num">NO.</td>
                <td className="author">작성자</td>
                <td className="title">제목</td>
                <td className="date">날짜</td>
              </tr>
            </thead>
            <tbody>
              {!isSearched
                ? contents?.map((el) => {
                    return (
                      <tr
                        key={el.id}
                        onClick={() => {
                          handleClickRow(el.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{el.id}</td>
                        <td>{el.author}</td>
                        <td>{el.title}</td>
                        <td>{el.date}</td>
                      </tr>
                    );
                  })
                : searchResult?.map((el) => {
                    return (
                      <tr
                        key={el.no}
                        onClick={() => {
                          handleClickRow(el.no);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{el.no}</td>
                        <td>{el.author}</td>
                        <td>{el.title}</td>
                        <td>{el.date}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        <div className="page-footer py-3">
          <nav className="d-flex justify-content-center">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {pageNumbers?.map((number) => (
                <li className="page-item" key={number}>
                  <a
                    className={
                      number === currentPage ? "page-link active" : "page-link"
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCurrentPage(number);
                    }}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Board;
