import Header from "./header";
import Footer from "./footer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileImage, FiletypePdf } from "react-bootstrap-icons";

function Detail({ isAuthorized }) {
  return (
    <>
      <Header></Header>
      <Main isAuthorized={isAuthorized}></Main>
      <Footer></Footer>
    </>
  );
}

function Main({ isAuthorized }) {
  const [payload, setPayload] = useState({});
  const { category, id } = useParams();
  const [fileType, setFileType] = useState(null);
  const [filePath, setFilePath] = useState(null);

  useEffect(() => {
    fetch(`/api/getone/${category}/${id}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setPayload(JSON.parse(data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [category, id]);

  useEffect(() => {
    if (payload.file) {
      const fileExtension = payload.file.slice(-3);
      setFilePath(payload.filepath);
      console.log(payload.filepath);
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        setFileType("img");
      } else if (fileExtension === "pdf") {
        setFileType("pdf");
      } else {
        console.log("지원하지 않는 파일 형식입니다.");
      }
    }
  }, [payload]);

  function handleClickUpdateBtn() {
    window.location.href = `/update/${category}/${id}`;
  }

  function handleClickDeleteBtn() {
    console.log(filePath);
    if (window.confirm("해당 게시글을 영구적으로 삭제하시겠습니까?")) {
      fetch(`/api/delete/${category}/${id}?filepath=${filePath}`, {
        method: "DELETE",
      })
        .then((response) => {
          return response.text();
        })
        .then((response) => {
          alert(JSON.parse(response).message);
          window.location.href = `/board/${category}`;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    window.location.href = `/board/${category}`;
  }

  function handleClickDownloadBtn() {
    const filepath = encodeURIComponent(payload.filepath);
    window.location.href = `http://localhost:5001/api/download/file?filepath=${filepath}`;
  }

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "125px", maxWidth: "780px" }}
      >
        {payload.id ? (
          <div>
            <div className="heading">
              <h1>{payload.title}</h1>
              <div className="d-flex">
                <div className="author fw-bold me-2">{payload.author}</div>
                <div className="date text-muted">{payload.date}</div>
              </div>
            </div>
            <div className="sub-heading mt-4 d-flex justify-content-between align-items-center">
              <h4 className="category-badge m-0">
                <span
                  className="badge rounded-pill text-bg-light text-primary"
                  style={{ fontWeight: "400" }}
                >
                  {category === "history" ? "연구사업" : "기타"}
                </span>
              </h4>
              <div className="action">
                {isAuthorized ? (
                  <>
                    <div
                      className="btn btn-outline-danger me-2"
                      onClick={handleClickUpdateBtn}
                    >
                      수정하기
                    </div>
                    <div
                      className="btn btn-danger"
                      onClick={handleClickDeleteBtn}
                    >
                      삭제하기
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <hr />
            <div className="body my-3 mb-5">
              {payload.file ? (
                <div
                  className="btn btn-outline-primary"
                  onClick={handleClickDownloadBtn}
                >
                  <div className="file-download d-flex align-items-center">
                    {fileType === "img" ? (
                      <FileImage size={30} />
                    ) : (
                      <FiletypePdf size={30} />
                    )}
                    <div className="text ms-2">
                      {payload.file ? payload.file : <></>}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: payload.contents }}
                style={{ maxWidth: "100%" }}
                className="mt-3"
              />
            </div>
          </div>
        ) : (
          <h1 className="my-5 py-5">
            해당 게시물은 삭제되었거나 존재하지 않습니다.
          </h1>
        )}
      </div>
    </>
  );
}

export default Detail;
