import { useState, useContext } from "react";
import { List } from "react-bootstrap-icons";
import LocaleContext from "../context/localeContext";

function Header() {
  const isAuthorized = useContext(LocaleContext);
  const [menu, setMenu] = useState(false);
  const menuObj = {
    "연구원 소개": "/introduction",
    "연구 사업": "/board/history",
    "이슈&솔루션": "/board/issue",
    "연구원 소식": "/board/news",
    "지역&나라발전": "/board/country",
  };

  const handleMouseOver = (event) => {
    event.target.classList.add("active");
  };

  const handleMouseLeave = (event) => {
    event.target.classList.remove("active");
  };

  function handleLogout() {
    fetch("/api/logout", {})
      .then((response) => response.text())
      .then((data) => {
        alert(JSON.parse(data).message);
        window.location.href = "/";
      });
  }

  return (
    <div className="fixed-top">
      <header
        id="main-header"
        className="App-header bg-white"
        style={{ boxShadow: "0px 3px 15px rgba(100, 100, 100, 0.49)" }}
      >
        <div className="container">
          <header className="d-flex justify-content-between py-3">
            <a
              href="/"
              className="d-flex align-items-center me-md-auto text-dark text-decoration-none"
            >
              <img
                style={{ width: "50px" }}
                src={process.env.PUBLIC_URL + "/korime_logo.png"}
                alt="Logo"
              />
            </a>

            <div className="mobile-nav">
              <div className="d-flex h-100">
                <div
                  className={`d-flex align-items-center btn-menu me-3`}
                  onClick={() => {
                    setMenu(!menu);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <List size={30}></List>
                </div>
                <div className="login d-flex align-items-center">
                  {isAuthorized ? (
                    <div className="btn btn-primary" onClick={handleLogout}>
                      로그아웃
                    </div>
                  ) : (
                    <div
                      className="btn btn-primary"
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                    >
                      로그인
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="pc-nav text-dark mx-4">
              {Object.keys(menuObj).map((key, i) => {
                return (
                  <div className="pc-nav-item" key={i}>
                    <a
                      href={menuObj[key]}
                      className="nav-link"
                      onMouseOver={handleMouseOver}
                      onMouseLeave={handleMouseLeave}
                    >
                      {key}
                    </a>
                  </div>
                );
              })}
              <div className="login d-flex align-items-center">
                {isAuthorized ? (
                  <div className="btn btn-primary" onClick={handleLogout}>
                    로그아웃
                  </div>
                ) : (
                  <div
                    className="btn btn-primary"
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                  >
                    로그인
                  </div>
                )}
              </div>
            </div>
          </header>
        </div>
      </header>
      <div
        className={`mobile-menu-list bg-white w-100 ${
          menu ? "active pb-2 pt-2" : ""
        }`}
        style={{ top: "82px", overflow: "hidden", position: "absolute" }}
      >
        {Object.keys(menuObj).map((key, i) => {
          return (
            <div className="mobile-nav-item py-1" key={i}>
              <a
                href={menuObj[key]}
                className="nav-link text-center"
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              >
                {key}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Header;
