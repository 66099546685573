import Header from "./header";
import Footer from "./footer";
import { ShieldSlash } from "react-bootstrap-icons";

function NoPermission() {
  return (
    <>
      <Header></Header>
      <Main></Main>
      <Footer></Footer>
    </>
  );
}

function Main() {
  return (
    <>
      <div className="container" style={{ marginTop: "125px" }}>
        <div className="row justify-content-center">
          <div className="col-md-6 col-12">
            <div className="card py-4 my-5">
              <div className="card-body d-flex flex-column align-items-center">
                <ShieldSlash
                  size={64}
                  color="gray"
                  className="my-3"
                ></ShieldSlash>
                <h3 className="card-title text-center mt-3">접근 권한 없음</h3>
                <p className="card-text text-center mb-5">
                  이 페이지에 접근하기 위해서는 로그인이 필요합니다.
                </p>
                <div className="btn-container d-flex mb-3">
                  <div
                    className="btn btn-secondary me-3"
                    style={{ width: "125px" }}
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    홈으로
                  </div>
                  <div
                    className="btn btn-primary"
                    style={{ width: "125px" }}
                    onClick={() => {
                      window.location.href = "/login";
                    }}
                  >
                    로그인하기
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoPermission;
